<template>
  <!-- Seekbar -->
  <div class="flex items-center justify-between w-full mt-2 space-x-4 flex-nowrap">
    <div class="text-[11px] text-dark-100 flex-none min-w-[40px] text-right select-none">
      {{ audioPlayerNewStore.getCurrentAudio.formattedTime }}
    </div>
    <input
      v-model="currentAudioProgressInPercent"
      class="transition duration-150 scale-105"
      :style="`--val: ${currentAudioProgressInPercent}`"
      name="range"
      type="range"
      min="0"
      max="100"
      step=".1"
      @change="seek($event)"
    >
    <div class="text-[11px] text-dark-100 flex-none min-w-[40px] text-left select-none">
      -{{ audioPlayerNewStore.getCurrentAudio.formattedTimeRemaining }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAudioPlayerNewStore } from '@/stores/audioPlayerNew'

const audioPlayerNewStore = useAudioPlayerNewStore()
const isSliding = ref(false)
const currentProgressInPercent = ref(0)
const currentAudioProgressInPercent = computed({
  get() {
    if (isSliding.value === true) { return currentProgressInPercent.value }
    else { return audioPlayerNewStore.getCurrentAudio.currentProgressInPercent }
  },
  set(percentage) {
    isSliding.value = true
    currentProgressInPercent.value = percentage
  },
})

// Seek audio to percentage
function seek(event: Event) {
  const target = event.target as HTMLInputElement
  const value = Number(target.value)
  isSliding.value = false
  audioPlayerNewStore.seekAudioToPercent(value)
}
</script>

<style lang="scss" scoped>
$track-width: 100%;
$track-height: 0.25em;
$thumb-size: 0.85em;
$thumb-color: #03f8f8;
$track-color: #ccc;
$fill-color: #03f8f8;

@mixin track($fill: 0) {
  box-sizing: border-box;
  border: none;
  width: $track-width;
  height: $track-height;
  border-radius: 100px;
  background: linear-gradient($fill-color, $fill-color) 0 / var(--sx) 100%
    no-repeat $track-color;
}

@mixin fill() {
  height: $track-height;
  background: $fill-color;
}

@mixin thumb() {
  box-sizing: border-box;
  border: none;
  width: $thumb-size;
  height: $thumb-size;
  padding: 2px;
  border-radius: 50%;
  background: $thumb-color;
  cursor: pointer;
}

[type="range"] {
  --range: 100;
  --ratio: calc((var(--val) - 0) / var(--range));
  --sx: calc(0.5 *#{$thumb-size} + var(--ratio) * (100% - #{$thumb-size}));

  margin: 0;
  padding: 0;
  width: $track-width;
  height: $thumb-size;
  background: transparent;
  font: 1em/1 arial, sans-serif;

  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-webkit-slider-runnable-track {
    @include track(1);
  }
  &::-moz-range-track {
    @include track;
  }
  &::-ms-track {
    @include track;
  }

  &::-moz-range-progress {
    @include fill;
  }
  &::-ms-fill-lower {
    @include fill;
  }

  &::-webkit-slider-thumb {
    margin-top: 0.5 * ($track-height - $thumb-size);
    @include thumb;
  }
  &::-moz-range-thumb {
    @include thumb;
  }
  &::-ms-thumb {
    margin-top: 0;
    @include thumb;
  }

  &::-ms-tooltip {
    display: none;
  }
}
</style>
